import Header from "./Components/Header/Header";
import HomeFront from "./Components/HomeFront/HomeFront";
import Footer from "./Components/Footer/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Blog from "./Components/Blog/Blog";
import Admin from "./Components/Admin/Admin";
import PageNotFound from "./Components/404/PageNotFound";
import Messages from "./Components/Admin/AdminComponents/Messages";
import ProgramSkills from "./Components/Admin/AdminComponents/ProgramSkills";
import Portfolio from "./Components/Admin/AdminComponents/Portfolio";
import "./App.css";

// Add backend error show functionality in contact page.

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Header />}>
            <Route index element={<HomeFront />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="blog" element={<Blog />} />
            <Route path="admin" element={<Admin />}>
              <Route path="messages" element={<Messages />} />
              <Route path="programskills" element={<ProgramSkills />} />
              <Route path="portfolio" element={<Portfolio />} />
            </Route>
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
