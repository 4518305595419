import axios from "axios";
import React, { useState } from "react";
import styles from "./Admin.module.css";
import AdminDash from "./AdminDash";

function Admin() {
  const [loginValues, setLoginValues] = useState({
    username: "",
    password: "",
  });
  const [loggedIn, setLoggedIn] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginValues({
      ...loginValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!loginValues.username || !loginValues.password) return;

    // Checking credentials from database
    axios({
      method: "post",
      url: "https://nikshit-me-server.onrender.com/api/login",
      // url: "http://localhost:3000/api/login",
      data: {
        username: loginValues.username,
        password: loginValues.password,
      },
    })
      .then((data) => setLoggedIn(data.data))
      .catch((err) => console.log(err));

    setLoginValues({
      username: "",
      password: "",
    });
  };

  return (
    <>
      {/* Login */}
      {!loggedIn && (
        <div className={styles.container}>
          <form className={styles.login_container} onSubmit={handleSubmit}>
            <h1 className={styles.login_heading}>Admin Dashboard</h1>

            <input
              className={styles.login_input}
              type="text"
              placeholder="username"
              name="username"
              value={loginValues.username}
              onChange={handleChange}
            />
            <input
              className={styles.login_input}
              type="password"
              placeholder="password"
              name="password"
              value={loginValues.password}
              onChange={handleChange}
            />
            <button className={styles.login_btn} type="submit">
              Log In
            </button>
          </form>
        </div>
      )}
      {/* Admin Dashboard */}
      {loggedIn && (
        <div>
          <AdminDash setLoggedIn={setLoggedIn} />
        </div>
      )}
    </>
  );
}

export default Admin;
