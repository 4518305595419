import React from "react";
import Laptop from "../../assets/Laptop.png";
import styles from "./About.module.css";

function About() {
  return (
    <>
      <div className={styles.top_div}></div>
      <div className={styles.container}>
        <div className={styles.left_container}>
          <h1 className={styles.heading}>About</h1>
          <p className={styles.subheading}>
            Passionate MERN Developer | Full-Stack Enthusiast | Software
            Engineer
          </p>
          <p className={styles.paragraph}>
            Greetings! Welcome to my Portfolio. I am a dedicated MERN (MongoDB,
            Express.js, React, Node.js) developer with a relentless curiosity
            for technology and a flair for crafting exceptional web experiences.
            With a solid foundation in computer applications and a track record
            of innovative projects, I am driven by my passion for coding and my
            love for exploring the endless possibilities of tech.
          </p>
          <p className={styles.subheading}>👨‍💻 Full-Stack Proficiency:</p>
          <p className={styles.paragraph}>
            {" "}
            As a MERN developer, I possess a comprehensive skill set across the
            entire web development spectrum. From back-end logic and database
            management to front-end design and user interactions, I'm committed
            to delivering seamless and user-centric applications.
          </p>
        </div>
        <div className={styles.right_container}>
          <img src={Laptop} alt="" />
        </div>
      </div>
      <div className={styles.secondContainer}>
        <div>
          <p className={styles.subheading}>🚀 Project Excellence:</p>
          <p className={styles.paragraph}>
            {" "}
            Having successfully completed some web development projects using
            the MERN stack, I have firsthand experience in turning creative
            concepts into functional realities. My projects showcase not only
            technical prowess but also a knack for solving real-world problems
            through technology. I excel in designing robust APIs that fuel the
            backbone of applications, ensuring smooth communication between
            various components. My focus on API design principles helps create
            scalable, efficient, and secure systems.
          </p>
          <p className={styles.subheading}>🌐 Tech Enthusiasm:</p>
          <p className={styles.paragraph}>
            {" "}
            I thrive on exploring the latest trends and innovations in the tech
            landscape. This curiosity not only keeps me engaged but also
            empowers me to adapt quickly to emerging technologies and
            incorporate them into my work.
          </p>
          <p className={styles.subheading}>🎓 My Educational Journey: </p>
          <p className={styles.paragraph}>
            DIT University, Dehradun provided me with a strong foundation in
            computer science concepts, which I continue to build upon as a
            lifelong learner in the ever-evolving world of technology.
          </p>
          <p className={styles.subheading}>🔗 Let's Connect:</p>
          <p className={styles.paragraph}>
            I'm eager to connect with fellow tech enthusiasts, developers, and
            professionals who share my passion for innovation. Whether it's
            discussing the intricacies of full-stack development, sharing
            insights about API design, or exploring the intersection of
            technology and real-world applications, I'm here to engage in
            meaningful conversations.
          </p>
          <p className={styles.paragraph}>
            Feel free to reach out, and let's collaborate on exciting projects,
            exchange ideas, or simply geek out about the fascinating world of
            software development. Together, we can drive technological progress
            and create solutions that shape the future.
          </p>
        </div>
      </div>
    </>
  );
}

export default About;
