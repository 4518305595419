import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import styles from "./Footer.module.css";

function Footer() {
  return (
    <div className={styles.footer}>
      <hr className={styles.hr} />
      <div className={styles.primary_footer}>
        <p className={styles.p}>
          Designed By{" "}
          <a href="https://brioodev.com" target="_blank" rel="noreferrer">
            Brioodev
          </a>
        </p>
        <div className={styles.nav_items}>
          <a
            href="https://www.instagram.com/nikshit.me"
            target="_blank"
            rel="noreferrer"
            className={styles.nav_item}
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://www.facebook.com/nikshitsehgal73"
            target="_blank"
            rel="noreferrer"
            className={styles.nav_item}
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a
            href="https://www.twitter.com/nikshit_me"
            target="_blank"
            rel="noreferrer"
            className={styles.nav_item}
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            href="https://www.linkedin.com/in/nikshitsehgal"
            target="_blank"
            rel="noreferrer"
            className={styles.nav_item}
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a
            href="https://github.com/Nikshitsehgal"
            target="_blank"
            rel="noreferrer"
            className={styles.nav_item}
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a
            href="mailto:nikshit.me@gmail.com"
            target="_blank"
            rel="noreferrer"
            className={styles.nav_item}
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
