import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import styles from "../Admin.module.css";
import axios from "axios";

function Portfolio() {
  const [input, setInput] = useState("");
  const [linkInput, setLinkInput] = useState("");
  const [projectType, setProjectType] = useState("projects");
  const [projectData, setProjectData] = useState([]);
  const [deleteId, setDeleteId] = useState(false);

  // useEffect(() => {
  //   console.log(projectType);
  // }, [projectType]);

  // Saving skills to the database
  const handleSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: "https://nikshit-me-server.onrender.com/api/portfolioset",
      data: {
        type: projectType,
        name: input,
        link: linkInput,
      },
    })
      .then((data) => {
        // console.log("saved data");
        // console.log(data.data);
        setInput("");
        setLinkInput("");
        setDeleteId(!deleteId);
      })
      .catch((err) => console.log(err));
  };

  //Getting skills from database
  useEffect(() => {
    axios("https://nikshit-me-server.onrender.com/api/getportfolio").then(
      (data) => {
        // console.log("getting data");
        setProjectData(data.data);
      }
    );
  }, [deleteId]);

  //Filtering skills into different categories
  const projects = projectData.filter((project) => project.type === "projects");
  const clones = projectData.filter((project) => project.type === "clones");
  const work = projectData.filter((project) => project.type === "work");

  //Deleting a Skill
  const handleDelete = (id) => {
    axios({
      method: "delete",
      url: "https://nikshit-me-server.onrender.com/api/deleteportfolio",
      data: {
        id: id,
      },
    })
      .then((data) => {
        // console.log("deleting data", data);
      })
      .catch((err) => console.log(err));
    setDeleteId(!deleteId);
  };

  return (
    <div className={styles.sub_container}>
      <form onSubmit={(e) => handleSubmit(e)} className={styles.form}>
        <select
          className={styles.dropdown}
          name="projectType"
          id="projectType"
          onChange={(e) => setProjectType(e.target.value)}
        >
          <option value="projects">Projects</option>
          <option value="clones">Clones</option>
          <option value="work">Work</option>
        </select>

        <input
          type="text"
          placeholder="Enter name"
          name="input"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className={styles.skillInput}
        />

        <input
          type="text"
          placeholder="Enter Link"
          name="linkInput"
          value={linkInput}
          onChange={(e) => setLinkInput(e.target.value)}
          className={styles.skillInput}
        />
        <button
          type="submit"
          disabled={!input || !linkInput}
          className={styles.addBtn}
        >
          Add
        </button>
      </form>
      <hr className={styles.breakdown} />

      {/* Projects */}
      <h2>Projects</h2>
      <ul className={styles.designingSkillList}>
        {projects.map((project) => (
          <li key={project._id}>
            <p>{project.name}</p>
            <FontAwesomeIcon
              onClick={() => handleDelete(project._id)}
              className={styles.deleteIcon}
              icon={faTrashCan}
            />
          </li>
        ))}
      </ul>

      {/* Clones */}
      <h2>Clones</h2>
      <ul className={styles.designingSkillList}>
        {clones.map((project) => (
          <li key={project._id}>
            <p>{project.name}</p>
            <FontAwesomeIcon
              onClick={() => handleDelete(project._id)}
              className={styles.deleteIcon}
              icon={faTrashCan}
            />
          </li>
        ))}
      </ul>

      {/* Work */}
      <h2>Work</h2>
      <ul className={styles.designingSkillList}>
        {work.map((project) => (
          <li key={project._id}>
            <p>{project.name}</p>
            <FontAwesomeIcon
              onClick={() => handleDelete(project._id)}
              className={styles.deleteIcon}
              icon={faTrashCan}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Portfolio;
