import React from "react";
import Laptop from "../../assets/Laptop.png";
import styles from "./Blog.module.css";

function Blog() {
  return (
    <>
      <div className={styles.top_div}></div>
      <div className={styles.container}>
        <div className={styles.left_container}>
          <h1 className={styles.heading}>Blog</h1>
          <p className={styles.paragraph}>Coming Soon...</p>
        </div>
        <div className={styles.right_container}>
          <img src={Laptop} alt="" />
        </div>
      </div>
    </>
  );
}

export default Blog;
