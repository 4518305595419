import React, { useEffect, useState } from "react";
import Nikshit_image from "../../assets/Nikshit_Image.png";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./HomeFront.module.css";
import { Link, Outlet } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import LoadingSpinner from "../LoadingSpinner";

function HomeFront() {
  const [skillData, setSkillData] = useState([]);
  const [projectData, setProjectData] = useState([]);

  const boxStyle = {
    background: "transparent",
    color: "white",
    "box-shadow": "none",
    // border: "1px solid #fdb813",
    "padding-top": "0px",
  };

  useEffect(() => {
    trackPromise(
      axios("https://nikshit-me-server.onrender.com/api/getskills").then(
        (data) => {
          setSkillData(data.data);
        }
      )
    );
  }, []);

  useEffect(() => {
    trackPromise(
      axios("https://nikshit-me-server.onrender.com/api/getportfolio").then(
        (data) => {
          setProjectData(data.data);
        }
      )
    );
  }, []);

  //Filtering skills into different categories
  const programmingSkills = skillData.filter(
    (skill) => skill.type === "program"
  );
  const designingSkills = skillData.filter((skill) => skill.type === "design");
  const projects = projectData.filter((project) => project.type === "projects");
  const clones = projectData.filter((project) => project.type === "clones");
  const work = projectData.filter((project) => project.type === "work");

  return (
    <section>
      {/* Front Container */}
      <div className={styles.container}>
        {/* Left Container */}
        <div className={styles.left}>
          <p className={styles.intro}>Hi! I'm Nikshit Sehgal.</p>
          <h1 className={styles.tagline}>
            A developer with a knack for problem solving.
          </h1>
          <p className={styles.description}>
            I’m a Full Stack developer and designer who loves to build digital
            products.
          </p>
          <Link to="about" className={styles.aboutBtn}>
            About
          </Link>
          <div className={styles.downArrow}>
            <FontAwesomeIcon
              className={styles.animate}
              icon={faAngleDoubleDown}
            />
          </div>
        </div>

        {/* Right Container */}
        <div className={styles.right}>
          <img src={Nikshit_image} alt="" />
        </div>
      </div>

      {/* Timeline Container */}
      <div className={styles.loading_center}>
        <LoadingSpinner />
      </div>
      <div className={styles.timeline_container}>
        <VerticalTimeline
          // className={styles.timeline}
          layout={"1-column-left"}
          lineColor={"#fdb813"}
        >
          {/* Programming Skills */}
          <VerticalTimelineElement
            className={styles.timeline}
            contentStyle={boxStyle}
            contentArrowStyle={{ border: "none" }}
          >
            <h3 className={styles.heading1}>Programming Skills</h3>
            <ul className={styles.designingSkillList}>
              {programmingSkills.map((skill) => (
                <li key={skill._id}>{skill.skill}</li>
              ))}
            </ul>
          </VerticalTimelineElement>

          {/* Designing Skills */}
          <VerticalTimelineElement
            contentStyle={boxStyle}
            contentArrowStyle={{ border: "none" }}
          >
            <h3 className={styles.heading1}>Designing Skills</h3>
            <ul className={styles.designingSkillList}>
              {designingSkills.map((skill) => (
                <li key={skill._id}>{skill.skill}</li>
              ))}
            </ul>
          </VerticalTimelineElement>

          {/* Portfolio */}
          <VerticalTimelineElement
            contentStyle={boxStyle}
            contentArrowStyle={{ border: "none" }}
          >
            <h3 className={styles.heading1}>Portfolio</h3>
            <h4 className={styles.sub_heading}>
              <FontAwesomeIcon icon={faAngleRight} /> Projects
            </h4>
            <ul className={styles.designingSkillList}>
              {projects.map((project) => (
                <li key={project._id} className={styles.project_btn}>
                  <a href={project.link} target="_blank" rel="noreferrer">
                    {project.name}
                  </a>
                </li>
              ))}
            </ul>

            <h4 className={styles.sub_heading}>
              <FontAwesomeIcon icon={faAngleRight} /> Clones
            </h4>
            <ul className={styles.designingSkillList}>
              {clones.map((project) => (
                <li key={project._id} className={styles.project_btn}>
                  <a href={project.link} target="_blank" rel="noreferrer">
                    {project.name}
                  </a>
                </li>
              ))}
            </ul>

            <h4 className={styles.sub_heading}>
              <FontAwesomeIcon icon={faAngleRight} /> Work
            </h4>
            <ul className={styles.designingSkillList}>
              {work.map((project) => (
                <li key={project._id} className={styles.project_btn}>
                  <a href={project.link} target="_blank" rel="noreferrer">
                    {project.name}
                  </a>
                </li>
              ))}
            </ul>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>

      <div className={styles.resume_div}>
        <p>Get to know me better!</p>
        <a
          href={process.env.REACT_APP_RESUME_LINK}
          target="_blank"
          rel="noreferrer"
          className={styles.resumeBtn}
        >
          Resume
        </a>
      </div>
      <Outlet />
    </section>
  );
}

export default HomeFront;
