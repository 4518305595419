import React from "react";
import styles from "./Admin.module.css";
import ToggleHeader from "./AdminComponents/ToggleHeader";

function AdminDash({ setLoggedIn }) {
  return (
    <div className={styles.admin_container}>
      <div className={styles.top_div}>
        <h1 className={styles.admin_heading}>Admin Dashboard</h1>
        <button className={styles.logOutBtn} onClick={() => setLoggedIn(false)}>
          Log Out
        </button>
      </div>

      {/* Router */}
      <div>
        <ToggleHeader />
      </div>
    </div>
  );
}

export default AdminDash;
