export default function validateInfo(values) {
  let errors = {};
  let valid = {};

  if (!values.name.trim()) {
    errors.name = "Name is required";
  } else {
    valid.name = "Name looks Great!";
  }

  //Email
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid";
  } else {
    valid.email = "Email Validated!";
  }

  if (!values.message) {
    errors.message = "Message is required";
  } else {
    valid.message = "Seems good!";
  }

  return { errors, valid };
}
