import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "../Admin.module.css";

function Messages() {
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    axios("https://nikshit-me-server.onrender.com/api/getmessages").then(
      (data) => {
        setFormData(data.data);
      }
    );
  }, []);

  return (
    <div className={styles.sub_container}>
      <table className={styles.message_table}>
        <thead className={styles.thead}>
          <tr>
            <td>S.No.</td>
            <td>Name</td>
            <td>Email</td>
            <td>Message</td>
            <td>Time</td>
            <td>Reply</td>
          </tr>
        </thead>
        <tbody>
          {formData.map((data, i) => (
            <tr key={data._id}>
              <td>{i + 1}</td>
              <td>{data.name}</td>
              <td>{data.email}</td>
              <td>{data.message}</td>
              <td>
                {new Date(data.created_at).toLocaleDateString()}
                {"  "}
                {new Date(data.created_at).toLocaleTimeString()}
              </td>
              <td>
                <a
                  href={`mailto:${data.email}?Subject=nikshit.me%20Inquiry`}
                  className={styles.replyBtn}
                >
                  Reply
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Messages;
