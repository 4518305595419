import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import classNames from "classnames";
import styles from "../Admin.module.css";

function ToggleHeader() {
  const location = useLocation();

  return (
    <div>
      <div className={styles.ToggleHeader}>
        <Link
          to="messages"
          className={
            location.pathname === "/admin/messages"
              ? classNames(styles.navBtn, styles.activeBtn)
              : styles.navBtn
          }
        >
          Messages
        </Link>
        <Link
          to="programskills"
          className={
            location.pathname === "/admin/programskills"
              ? classNames(styles.navBtn, styles.activeBtn)
              : styles.navBtn
          }
        >
          Programming Skills
        </Link>

        <Link
          to="portfolio"
          className={
            location.pathname === "/admin/portfolio"
              ? classNames(styles.navBtn, styles.activeBtn)
              : styles.navBtn
          }
        >
          Portfolio
        </Link>
      </div>
      <Outlet />
    </div>
  );
}

export default ToggleHeader;
