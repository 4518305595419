import React, { useEffect, useState } from "react";
import validateInfo from "../validateInfo";
import Laptop from "../../assets/Laptop.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import styles from "./Contact.module.css";

function Contact() {
  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [valid, setValid] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    const errorData = validateInfo(values);
    setErrors(errorData.errors);
    setValid(errorData.valid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorData = validateInfo(values);
    setErrors(errorData.errors);
    setValid(errorData.valid);
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(valid).length === 3 &&
      isSubmitting
    ) {
      console.log("Successfully submitted... GO Ahead!");

      // Add data to database
      axios({
        method: "post",
        url: "https://nikshit-me-server.onrender.com/api/contactform",
        // url: "http://localhost:3000/api/contactform",
        data: {
          name: values.name,
          email: values.email,
          message: values.message,
        },
      });

      setValues({
        name: "",
        email: "",
        message: "",
      });
      setErrors({});
      setValid({});
      setIsSubmitted(true);

      return setIsSubmitting(false);
    } else {
      return setIsSubmitting(false);
    }
  }, [isSubmitting]);

  useEffect(() => {
    return setTimeout(() => {
      setIsSubmitted(false);
    }, 5000);
  }, [isSubmitted]);

  return (
    <>
      <div className={styles.top_div}></div>
      <div className={styles.container}>
        <div className={styles.left_container}>
          <h1 className={styles.heading}>Get in Touch</h1>
          <p className={styles.sub_heading}>Fill the form below</p>

          {/* Form */}

          <form className={styles.form} onSubmit={handleSubmit}>
            <input
              className={styles.form_input}
              type="text"
              name="name"
              placeholder="Name"
              value={values.name}
              onChange={handleChange}
            />
            {errors.name && (
              <p className={styles.error_message}>
                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.name}
              </p>
            )}
            {valid.name && (
              <p className={styles.validation_message}>
                <FontAwesomeIcon icon={faCircleCheck} /> {valid.name}
              </p>
            )}

            <input
              className={styles.form_input}
              type="email"
              name="email"
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && (
              <p className={styles.error_message}>
                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.email}
              </p>
            )}
            {valid.email && (
              <p className={styles.validation_message}>
                <FontAwesomeIcon icon={faCircleCheck} /> {valid.email}
              </p>
            )}

            <textarea
              className={styles.form_input}
              type="text"
              name="message"
              placeholder="Message"
              value={values.message}
              onChange={handleChange}
            />
            {errors.message && (
              <p className={styles.error_message}>
                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.message}
              </p>
            )}
            {valid.message && (
              <p className={styles.validation_message}>
                <FontAwesomeIcon icon={faCircleCheck} /> {valid.message}
              </p>
            )}

            <button
              type="submit"
              disabled={Object.keys(valid).length < 3}
              className={styles.submitBtn}
            >
              Submit
            </button>
          </form>
          {isSubmitted && (
            <p className={styles.submitted_msg}>
              <FontAwesomeIcon icon={faCircleCheck} /> Success. We will contact
              you soon on your email.
            </p>
          )}
        </div>
        <div className={styles.right_container}>
          <img src={Laptop} alt="" />
        </div>
      </div>
    </>
  );
}

export default Contact;
