import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";

export default function LoadingSpinner() {
  const { promiseInProgress } = usePromiseTracker();
  return (
    <div>
      {promiseInProgress === true ? (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#fdb813"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      ) : null}
    </div>
  );
}
