import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import styles from "../Admin.module.css";
import axios from "axios";

function ProgramSkills() {
  const [input, setInput] = useState("");
  const [skillType, setSkillType] = useState("program");
  const [skillData, setSkillData] = useState([]);
  const [deleteId, setDeleteId] = useState(false);

  useEffect(() => {
    console.log(skillType);
  }, [skillType]);

  // Saving skills to the database
  const handleSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: "https://nikshit-me-server.onrender.com/api/skillset",
      // url: "http://localhost:3000/api/skillset",
      data: {
        type: skillType,
        skill: input,
      },
    })
      .then((data) => {
        console.log("saved data");
        console.log(data.data);
        setInput("");
        setDeleteId(!deleteId);
      })
      .catch((err) => console.log(err));
  };

  //Getting skills from database
  useEffect(() => {
    axios("https://nikshit-me-server.onrender.com/api/getskills").then(
      (data) => {
        console.log("getting data");
        setSkillData(data.data);
      }
    );
  }, [deleteId]);

  //Filtering skills into different categories
  const programmingSkills = skillData.filter(
    (skill) => skill.type === "program"
  );
  const designingSkills = skillData.filter((skill) => skill.type === "design");

  //Deleting a Skill
  const handleDelete = (id) => {
    axios({
      method: "delete",
      url: "https://nikshit-me-server.onrender.com/api/deleteskill",
      data: {
        id: id,
      },
    })
      .then((data) => {
        console.log("deleting data", data);
        setDeleteId(!deleteId);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={styles.sub_container}>
      <form onSubmit={(e) => handleSubmit(e)} className={styles.form}>
        <select
          className={styles.dropdown}
          name="skillType"
          id="skillType"
          onChange={(e) => setSkillType(e.target.value)}
        >
          <option value="program">Programming Skills</option>
          <option value="design">Designing Skills</option>
        </select>

        <input
          type="text"
          placeholder="Enter a skill"
          name="skillInput"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className={styles.skillInput}
        />
        <button type="submit" disabled={!input} className={styles.addBtn}>
          Add
        </button>
      </form>
      <hr className={styles.breakdown} />

      {/* Programming Skills */}
      <h2>Programming Skills</h2>
      <ul className={styles.designingSkillList}>
        {programmingSkills.map((skill) => (
          <li key={skill._id}>
            <p>{skill.skill}</p>
            <FontAwesomeIcon
              onClick={() => handleDelete(skill._id)}
              className={styles.deleteIcon}
              icon={faTrashCan}
            />
          </li>
        ))}
      </ul>

      {/* Designing Skills */}
      <h2>Designing Skills</h2>
      <ul className={styles.designingSkillList}>
        {designingSkills.map((skill) => (
          <li key={skill._id}>
            <p>{skill.skill}</p>
            <FontAwesomeIcon
              onClick={() => handleDelete(skill._id)}
              className={styles.deleteIcon}
              icon={faTrashCan}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ProgramSkills;
