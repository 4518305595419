import React from "react";

function PageNotFound() {
  return (
    <div
      style={{
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <p style={{ color: "gray", fontSize: "25px" }}>404 | Page Not Found</p>
      <a style={{ color: "gray" }} href="/">
        Go back to Home
      </a>
    </div>
  );
}

export default PageNotFound;
