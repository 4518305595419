import classNames from "classnames";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import NsLogo from "../../assets/Logo.png";
import styles from "./Header.module.css";

function Header() {
  return (
    <>
      <nav className={styles.nav}>
        <Link className={styles.brand} to="/">
          <img className={styles.brandLogo} src={NsLogo} alt="Nikshit Sehgal" />
          <p className={styles.brandName}>nikshit.me</p>
        </Link>

        <div className={styles.nav_items}>
          <Link
            className={classNames(styles.nav_item, styles.admin_link)}
            to="/admin"
          >
            ..
          </Link>
          <Link className={styles.nav_item} to="/about">
            About
          </Link>
          <Link className={styles.nav_item} to="/contact">
            Contact
          </Link>
          <Link className={styles.nav_item} to="/blog">
            Blog
          </Link>
        </div>
      </nav>
      <Outlet />
    </>
  );
}

export default Header;
